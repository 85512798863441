
  import { mapGetters } from 'vuex'

  export default {
    name: `AeqDocumentList`,
    props: {
      token: {
        type: String,
        default: null
      },
      light: {
        type: Boolean,
        default: false
      },
      detailed: {
        type: Boolean,
        default: true
      },
      preview: {
        type: Boolean,
        default: false
      },
      value: {
        type: Array,
        default: () => []
      },
      responsive: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        previewDialog: false,
        hint: this.$attrs.hint,
        pdfUrl: null,
        pdfBlobUrl: null,
        label: this.$attrs.label,
        innerValue: this.value.map((doc) => ({ ...doc, ...doc?.metadata })) || []
      }
    },
    computed: {
      ...mapGetters({
        isClient: `permissions/userIsClient`,
        isMaster: `permissions/userIsMaster`,
        isConsultOnly: `permissions/userIsConsultonly`,
        isCandidate: `permissions/userIsCandidate`,
        isExternal: `permissions/userIsExternal`
      })
    },
    watch: {
      value: {
        deep: true,
        handler(newValue) {
          if (Array.isArray(newValue)) {
            this.innerValue = newValue.map((doc) => ({ ...doc, ...doc?.metadata }))
          }
        }
      }
    },
    mounted() {
      console.log(`DOCS`)
    },
    beforeDestroy() {
      this.revokeObjectURL()
    },
    methods: {
      getIcon(doc) {
        const type = !!doc.contentType ? doc.contentType : doc?.data?.split(`;`)[0] || `file`

        // PDF
        if (type.includes(`pdf`)) {
          return `mdi-file-pdf-box mdi-file-pdf`
        }

        // IMAGE
        if (type.includes(`image`)) {
          return `mdi-file-image`
        }

        // MS WORD
        if (type.includes(`msword`) || type.includes(`wordprocessingml`)) {
          return `mdi-microsoft-word`
        }

        // MS EXCEL
        if (type.includes(`csv`) || type.includes(`spreadsheetml`)) {
          return `mdi-microsoft-excel`
        }

        return `mdi-file`
      },
      previewDocument(doc) {
        if (!!doc.data) {
          this.pdfBlobUrl = doc.data
          this.pdfUrl = URL.createObjectURL(this.pdfBlobUrl)
          this.previewDialog = true
        } else if (!!doc.downloadLink) {
          const getDocument = !!this.token
            ? (d) => this.$api.external.getOneDocument(d)
            : (d) => this.$api.documents.getOne(d)
          getDocument(doc.downloadLink)
            .then((res) => {
              if (!!res && !!res._data) {
                this.pdfBlobUrl = res._data
                this.pdfUrl = URL.createObjectURL(this.pdfBlobUrl)
                this.previewDialog = true
              }
            })
            .catch((e) => {
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_get_document`)
              })
            })
        }
      },
      revokeObjectURL() {
        if (!!this.pdfBlobUrl) {
          URL.revokeObjectURL(this.pdfBlobUrl)
        }
      },
      closePreviewDialog() {
        this.revokeObjectURL()
        this.pdfUrl = null
        this.pdfBlobUrl = null
        this.previewDialog = false
      },
      openDocument(doc) {
        console.log(`OPEN DOC`, doc)
        if (!!doc.data) {
          this.$helpers.FileSaver.saveAs(new Blob(this.$helpers.b64ToArrayBuffer(doc.data)), doc.fileName)
        } else if (!!doc.downloadLink) {
          const getDocument = !!this.token
            ? (d) => this.$api.external.getOneDocument(d)
            : (d) => this.$api.documents.getOne(d)
          getDocument(doc.downloadLink)
            .then((res) => {
              if (!!res && !!res._data) {
                this.$helpers.FileSaver.saveAs(new Blob([res._data]), doc.fileName)
              }
            })
            .catch(() => {
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_get_document`)
              })
            })
        }
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit(`click:open`, doc)
      },
      deleteDocument(doc) {
        this.$emit(`click:delete`, doc)
      }
    }
  }
